.home_hero {
    background-color: black;
    min-height: 750px;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding-bottom: 75px;
    padding-bottom: 9vh;
}
.hh_bg {
    width: 92%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
}
.hh_content {
    display: flex;
    justify-content: space-between;
    padding: 0 151px;
}
.hh_content .hh_social {
    display: inline-flex;
    gap: 48px;
    padding: 59px 0 0;
}
.hh_title {
    margin-top: 48px;
}
.hh_content .hh_mail {
    padding: 59px 00;
}
.hh_social a,
.hh_mail a {
    color: #E5DBCB;
    text-align: center;
    font-family: Warugaki;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
}
.hh_title h2 {
    color: #E5DBCB;
    text-align: center;
    font-family: Warugaki;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;
    padding-bottom: 12px;
}
.hh_title h1 {
    color: #E5DBCB;
    text-align: center;
    font-family: Signifier;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 105%;
}
.hh_bottom {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 4%;
    width: 100%;
    margin-top: auto;
}
.hh_meniu, 
.hh_reserve {
    position: relative;
    z-index: 10;
    cursor: pointer;
}
.hh_meniu img, 
.hh_reserve img {
    width: 100%;
}
.hh_meniu img {
    max-width: 350px;
}
.hh_reserve img {
    max-width: 418px;
}
.hh_shrimp {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
}
.hh_shrimp img {
    width: 100%;
}
.hh_koi {
    position: absolute;
    top: 0;
    right: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
}
.hh_koi img {
    width: 100%;
}
.no-scroll {
    overflow: hidden;
}

@media (min-width: 1560px) {
    .hh_bottom {
        padding-left: 12vw;
        padding-right: 8vw;
    }
    .hh_shrimp {
        top: -5vh;
    }
    .hh_social a,
    .hh_mail a {
        font-size: 18px;
    }
    .hh_title h2 {
        font-size: 40px;
    }
    .hh_title h1 {
        font-size: 24px;
    }
    .hh_shrimp {
        width: 42.5vw;
    }
    .hh_shrimp img {
        width: 100%;
    }
    .hh_koi {
        width: 35vw;
    }
}
@media (max-width: 1560px) {
    .hh_content {
        padding: 0 51px;
    }
    .hh_shrimp {
        width: 50%;
    }
    .hh_koi {
        width: 50%;
    }
}
@media (max-width: 1024px) {
    .hh_content {
        padding: 0 51px;
    }
    .hh_shrimp {
        width: 60%;
        top: 10vh;
    }
    .hh_koi {
        width: 60%;
        top: 10vh;
    }
}
@media (max-width: 768px) {
    .hh_bg {
        background-position: center 20%;
    }
    .home_hero {
        min-height: 750px;
    }
    .hh_content {
        padding: 0;
    }
    .hh_content .hh_social {
        padding: 51px 0 0;
    }
    .hh_title {
        margin-top: 40px;
    }
    .hh_content .hh_mail {
        padding: 51px 00;
    }
    .hh_shrimp {
        width: 60%;
        top: 40%;
    }
    .hh_koi {
        width: 60%;
        top: 35%;
    }

    /* anti high-light on click */

    .hh_meniu, .hh_reserve, .meniu_exit {
        -webkit-tap-highlight-color: transparent;
        outline: none;
        background-color: transparent;
    }

    .hh_meniu:focus, .hh_reserve:focus,
    .hh_meniu:active, .hh_reserve:active,
    .meniu_exit:active {
        outline: none;
        background-color: transparent;
        box-shadow: none;
    }
}
@media (min-width: 601px) {
    .hh_mobile_content {
        display: none;
    }
}
@media (max-width: 600px) {
    .home_hero {
        height: 100vh;
        min-height: 840px;
        display: block;
        padding-bottom: 48px;
    }
    .hh_bg {
        width: 100%;
        background-size: 150%;
        background-position: center 15%;
        background-origin: content-box;
    }
    .hh_mobile_content {
        display: flex;
        padding: 0 12px 12px;
        gap: 12px;
        justify-content: space-between;
        -webkit-tap-highlight-color: transparent;
    }
    .hh_mobile_content .hh_social {
        display: flex;
        gap: 19px;
    }
    .hh_content {
        display: flex;
        justify-content: center;
    }
    .hh_content .hh_social,
    .hh_content .hh_mail {
        display: none;
    }
    .hh_title {
        width: 100%;
        margin-top: 28px;
    }
    .hh_title h2 {
        padding-bottom: 24px;
        font-size: 30px;
    }
    .hh_bottom {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 32px;
    }
    .hh_meniu {
        width: 278px;
    }
    .hh_reserve {
        width: 332px;
    }
    .hh_koi,
    .hh_shrimp {
        width: auto;
        min-width: auto;
    }
    .hh_shrimp img,
    .hh_koi img {
        width: auto;
    }
    .hh_shrimp {
        top: 320px;
        left: 0;
    }
    .hh_koi {
        top: 450px;
        right: 0;
    }
}
@media (max-width: 425px) {
    .hh_bg {
        background-size: 200%;
    }
}
@font-face {
    font-family: 'Signifier';
    src:
        local("Signifier-Regular"), 
        url('./fonts/Signifier-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Signifier';
    src:
        local("Signifier-RegularItalic"), 
        url('./fonts/Signifier-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Warugaki';
    src:
        local("Warugaki-Regular"), 
        url('./fonts/Warugaki-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    outline: none;
    text-decoration: none;
    background-color: transparent;
}
a:focus,
a:active {
    outline: none;
    background-color: transparent;
    box-shadow: none;
}
img {
    background-color: transparent;
    user-select: none;
}
img:focus,
img:active {
    outline: none;
    background-color: transparent;
    box-shadow: none;
}
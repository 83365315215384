#meniu_main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
#meniu_main::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px); 
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 6%, black 94%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 6%, black 94%, transparent 100%);
    z-index: -1;
}
.meniu_maistas,
.meniu_gerimai {
    display: inline-block;
    color: white;
    position: relative;
    z-index: -1;
}
.meniu_maistas {
    transform: rotate(-3deg);
    margin-bottom: 20px;
}
.meniu_gerimai {
    transform: rotate(2.5deg);
    margin-bottom: 120px;
}
#meniu_main .meniu_exit {
    position: absolute;
    right: -75%;
    top: 17.5%;
    cursor: pointer;
    z-index: 1000;
}
.meniu_maistas > section {
    position: relative;
    width: 656px;
}
.meniu_maistas > section h2{
    padding: 32px 0 25px;
}
.meniu_gerimai > section {
    position: relative;
    width: 434px;
}
.meniu_gerimai > section h2{
    padding: 82px 0 25px;
}
.meniu_maistas > section img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.meniu_gerimai > section img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.mm_list {
    padding: 40px;
    position: relative;
    z-index: 100;
    text-align: center;
}

.mm_list section > div:not(:first-child) {
    margin: 13px 0;
}
.mm_list h2 {
    color: #E5DBCB;
    font-family: Warugaki;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;
}
.mm_list h4 {
    color: #E5DBCB;
    font-family: Warugaki;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;
}
.mm_list h5 {
    color: #E5DBCB;
    font-family: Signifier;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 105%;
    padding-top: 10px;
}
.mobile_m_menu,
.mobile_g_menu {
    display: none;
}
.meniu_maistas br {
    display: none;
}
@media (min-width: 1300px) {
    .meniu_maistas > section img {
        top: 42px;
    }
    .meniu_gerimai > section img {
        top: 72px;
    }
    .meniu_maistas .mm_list section {
        margin-top: 42px;
    }
    .meniu_gerimai .mm_list section {
        margin-top: 62px;
    }
}
@media (max-width: 1299.5px) {
    #meniu_main {
        flex-direction: column;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;
    }
    .meniu_maistas {
        margin-left: 0;
    }
    #meniu_main .meniu_exit {
        position: absolute;
        right: -5%;
        top: 6%;
        cursor: pointer;
        z-index: 1000;
    }
}
@media (max-width: 768px) {
    #meniu_main .meniu_exit {
        position: absolute;
        right: 10%;
        top: 10%;
        cursor: pointer;
        z-index: 1000;
    }
}
@media (max-width: 560px) {
    #meniu_main {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        top: -30px;
        height: calc(100% + 30px);
    }
    .meniu_maistas,
    .meniu_gerimai {
        transform: rotate(0);
        width: 118%;
        margin: 0;
    }
    .meniu_maistas > section,
    .meniu_gerimai > section {
        max-width: 656px;
        width: auto;
    }
    .mm_list h5 {
        font-size: 16px;
    }
    .desk_m_menu,
    .desk_g_menu {
        display: none;
    }
    .mobile_m_menu,
    .mobile_g_menu {
        display: block;
    }
    .mobile_m_menu {
        height: 824px;
    }
    .meniu_gerimai > section h2 {
        padding: 12px 0 24px;
    }
    .mobile_g_menu {
        height: 610px;
        top: -30px!important;
    }
    .meniu_maistas br {
        display: block;
    }
    #meniu_main .meniu_exit {
        position: absolute;
        right: 15%;
        top: 8%;
        cursor: pointer;
        z-index: 1000;
    }
}

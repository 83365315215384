#reserve_main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
}
#reserve_main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px); 
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 6%, black 94%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 6%, black 94%, transparent 100%);
    z-index: -1;
}
#reserve_main .meniu_exit {
    position: absolute;
    top: 46px;
    right: -107px;
    cursor: pointer;
}
#reserve_main > section {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 723px;
    max-width: 969px;
    padding: 40px;
    z-index: 100;
}
.reserve_desk,
.reserve_mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.res_text_area {
    position: relative;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
.res_text_area h2 {
    color: #E5DBCB;
    font-family: Warugaki;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    text-transform: uppercase;
    padding: 48px 0 24px;
}
.res_text_area h3 {
    color: #E5DBCB;
    font-family: Signifier;
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    line-height: 105%;
}
.res_text_area p {
    color: #7E7A76;
    font-family: Signifier;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 125%;
    padding: 48px 0 64px;
}
.res_buttons {
    position: relative;
    z-index: 13;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.reserve_mobile {
    display: none;
}
.res_text_area br {
    display: none;
}
@media (min-width: 1300px) {
    .res_text_area h3 {
        width: 60%;
    }
    .res_text_area p {
        width: 70%;
    }
}
@media (max-width: 1300px) {
    #reserve_main .meniu_exit {
        top: 66px;
        right: 76px;
    }
}
@media (max-width: 970px) {
    #reserve_main > section {
        height: auto;
        min-height: 723px;
    }
}
@media (max-width: 748px) {
    #reserve_main > section {
        height: auto;
        min-height: 783px;
    }
}
@media (max-width: 600px) {
    #reserve_main > section > div.res_buttons > a:nth-child(1) > img {
        width: 248px;
        margin: 0 auto;
    }
    #reserve_main > section > div.res_buttons > a:nth-child(2) > img {
        width: 317px;
        margin: 0 auto;
    }
    .reserve_desk {
        display: none;
    }
    .reserve_mobile {
        display: block;
        width: calc(100% + 76px);
        left: -38px;
        top: -40px;
        height: 100vh;
        min-height: 882px;
    }
    .res_text_area {
        width: auto;
    }
    #reserve_main .meniu_exit {
        top: 16px;
        right: 33px;
    }
    .res_text_area h2 {
        padding: 0 0 24px;
    }
    .res_text_area br {
        display: block;
    }
}